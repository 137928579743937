import { useEffect, useState } from "react";

enum DeviceType {
  DESKTOP = "desktop",
  MOBILE = "mobile",
  TABLET = "tablet",
}

export default function useDevice(): DeviceType {
  const getInitialDevice = (): DeviceType => {
    if (typeof navigator === "undefined") {
      return DeviceType.DESKTOP;
    }

    const userAgent = navigator?.userAgent?.toLowerCase();

    const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(
      userAgent
    );
    const isTablet =
      /(macintosh|ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(
        userAgent
      ) && navigator.maxTouchPoints > 0;

    if (isTablet) {
      return DeviceType.TABLET;
    } else if (isMobile) {
      return DeviceType.MOBILE;
    }

    return DeviceType.DESKTOP;
  };

  const [device, setDevice] = useState<DeviceType>(getInitialDevice);

  useEffect(() => {
    const handleDeviceDetection = () => {
      const newDevice = getInitialDevice();
      setDevice(newDevice);
    };

    handleDeviceDetection();
    window.addEventListener("resize", handleDeviceDetection);

    return () => {
      window.removeEventListener("resize", handleDeviceDetection);
    };
  }, []);

  return device;
}
