export const segmentExperimentStarted = (
  url: string,
  experimentName: string,
  data: Record<string, string>
) => {
  if (typeof window === undefined) return;

  window.analytics.track("Experiment Started", {
    experiment_name: experimentName,
    url,
    ...data,
  });
};
