import React, { useCallback, useEffect, useState } from "react";
import builder, { BuilderComponent } from "@builder.io/react";
import { graphql, HeadProps, navigate, PageProps } from "gatsby";
import SEOMeta from "@components/SEOMeta";
import { RegisterComponentPageBlank } from "@utils/RegisterComponentPageBlank";
import { MainProvider } from "@contexts/MainContext";
import useGtmPageChange from "@hooks/useGtmPageChange";
import generateUuId from "@utils/generateUuId";
import { mxpPageViewed } from "@utils/mixpanelEvents/mixpanelEvents";
import "../../libs/builder.setting";
import { enableSmoothScroll } from "@utils/enableSmoothScroll";
import useDevice from "../../hooks/useDevice";
import { Cookie } from "../../utils/Cookie";
import { segmentPageView } from "../../utils/handleOnRouteUpdate";
import { segmentExperimentStarted } from "../../utils/segment/experimentStarted";

type DataProps = {
  allBuilderModels: Queries.builder;
};

const PageTemplate: React.FC<PageProps<DataProps>> = ({ data, location }) => {
  // const content = data?.allBuilderModels.onePage?.content;

  const [content, setContent] = useState<
    Queries.builder_StaticPageFullNav["content"] | null
  >(null);
  const device = useDevice();

  const getContent = useCallback(() => {
    const isCohort = Cookie.checkCookie("RedesignCohort")
      ? parseInt(Cookie.getCookie("RedesignCohort") as string) === 2
        ? true
        : false
      : false;
    builder
      .get("page", {
        userAttributes: {
          urlPath: location.pathname,
          device,
          customer: Boolean(Cookie.getCookie("sp_last_access")),
          redesignCohort: isCohort,
          isPartner: Boolean(Cookie.getCookie("IS_PARTNER")),
          excludeSetPromoCodes: Boolean(Cookie.getCookie("PROMOCODE")),
        },
        options: {
          noTraverse: false,
          includeRefs: true,
        },
      })
      .toPromise()
      .then(res => {
        if (!res) navigate("/404/");

        setContent(res);
      });
  }, [location.pathname, device]);

  useEffect(() => {
    if (
      sessionStorage.getItem("pageCount") === "0" &&
      window.location.pathname === "/"
    ) {
      if (!Cookie.checkCookie("RedesignCohort")) {
        const cohort = Math.random() > 0.5 ? 1 : 2;
        Cookie.setCookie("RedesignCohort", cohort.toString(), 365);
        let intervalCounter = 0;
        const myInterval = setInterval(() => {
          if (Cookie.checkCookie("RedesignCohort") && window.analytics?.track) {
            segmentPageView({ pathname: "/" } as Location);
            getContent();
            if (!Cookie.checkCookie("ExperimentExclude")) {
              segmentExperimentStarted(
                location.pathname,
                "Website Redesign MVP",
                {
                  variant:
                    Cookie.getCookie("RedesignCohort") === "1"
                      ? "Original"
                      : "Variant",
                }
              );
            }
            clearInterval(myInterval);
          }
          intervalCounter++;
          if (intervalCounter >= 20) {
            getContent();
            clearInterval(myInterval);
          }
        }, 10);
      } else {
        getContent();
      }
    } else {
      getContent();
    }
  }, [getContent, location.pathname]);

  const uuid = generateUuId();

  useEffect(() => {
    setIsClient(true);
    mxpPageViewed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useGtmPageChange(uuid);

  const [isClient, setIsClient] = useState(false);

  const isScrollable = content?.data?.useScroll;
  enableSmoothScroll(isScrollable);

  return (
    <MainProvider>
      <React.Fragment key={`layout-${isClient}`}>
        <div>
          <main>
            {content && <BuilderComponent content={content} model="page" />}
          </main>
        </div>
      </React.Fragment>
    </MainProvider>
  );
};

export default PageTemplate;

RegisterComponentPageBlank();

export const Head: React.FC<HeadProps<DataProps>> = ({ data }) => {
  const {
    allBuilderModels: { onePage },
  } = data;

  const seoData = onePage?.content?.data as Queries.builder_PageData;

  return (
    <>
      <SEOMeta
        index={seoData?.index!}
        title={seoData?.title!}
        description={seoData?.description!}
      />
    </>
  );
};

export const query = graphql`
  query ($path: String!) {
    allBuilderModels {
      onePage(
        target: { urlPath: $path }
        options: { cachebust: false, includeRefs: true }
      ) {
        content
      }
    }
  }
`;
